<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- card title -->
          <h4 class="card-title">{{ $t('ylawContract') }}</h4>
          <hr/>
          <template v-if="!contractUrl?.length">
            <b-overlay
                :show="loadingFile"
                rounded
                opacity="0.6"
                spinner-variant="teal"
            >
              <label class="uploadFile">
                <i class="ri-upload-cloud-2-line"></i>
                <span>{{ $t('dropContract') }}</span>
                <input type="file" @change="uploadFile">
              </label>
            </b-overlay>
          </template>
          <template v-else>
            <b-overlay
                :show="loadingFile"
                rounded
                opacity="0.6"
                spinner-variant="teal"
            >
            <div class="file-container">
              <div  dir="ltr">
                <svg height="64px" width="64px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink"
                     viewBox="0 0 512 512" xml:space="preserve">
                <path style="fill:#E2E5E7;"
                      d="M128,0c-17.6,0-32,14.4-32,32v448c0,17.6,14.4,32,32,32h320c17.6,0,32-14.4,32-32V128L352,0H128z"/>
                  <path style="fill:#B0B7BD;" d="M384,128h96L352,0v96C352,113.6,366.4,128,384,128z"/>
                  <polygon style="fill:#CAD1D8;" points="480,224 384,128 480,128 "/>
                  <path style="fill:#F15642;" d="M416,416c0,8.8-7.2,16-16,16H48c-8.8,0-16-7.2-16-16V256c0-8.8,7.2-16,16-16h352c8.8,0,16,7.2,16,16
	V416z"/>
                  <g>

            <text x="125" y="365" fill="#fff" class="text">{{ fileExtension }}</text>
</g>
                  <path style="fill:#CAD1D8;" d="M400,432H96v16h304c8.8,0,16-7.2,16-16v-16C416,424.8,408.8,432,400,432z"/>
</svg>
              </div>
              <h6 class="file-name"><bdi>{{ fileName }}</bdi></h6>
            </div>
            <hr>
            <div class="info">
              <div class="date-modify">
                {{contractDate}}
              </div>
              <div class="actions">
                  <i class="ri-download-2-line " @click="downloadFile"></i>

                <label class="update-file">
                  <i class="ri-edit-2-line"></i>
                  <input type="file" @change="updateFile">
                </label>
                <i class="ri-delete-bin-7-line" @click="deleteFile"></i>
                <i class="ri-whatsapp-fill whatsapp-icon" @click="$emit('sendToWhatsApp')"></i>
              </div>
            </div>
            </b-overlay>
          </template>
        </div>
      </div>
    </div>
    <!-- end col -->
  </div>
</template>
<script>
import {UploadContract,updateContractFile,deleteContractFile} from "@/services/contracts"
import {HandleUploadError} from "@/helpers/firebase/handleUploadError"
import Swal from "sweetalert2";
import moment from "moment";

export default {
  props: {
    conractId: {
      required: true,
    },
    contractUrl: {
      required: false,
      default:null
    },
    contractDate: {
      required: false
    }
  },
  emits: ['contractChanged','sendToWhatsApp'],
  data() {
    return {
      loadingFile: false
    }
  },
  computed:{
    moment() {
      return moment
    },
    fileName(){
      if (this.contractUrl?.length){
        const decodedURL = decodeURIComponent(this.contractUrl);
        return  decodedURL.substring(decodedURL.lastIndexOf('/') + 1).split('?')[0];
      }else {
        return ''
      }
    },
    fileExtension(){
      return this.fileName.split('.').pop()
    }
  },
  methods: {
    errorMessage(errorMessageVal) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: this.$t('ops'),
        showConfirmButton: true,
        text: errorMessageVal
      });
    },
    async uploadFile(event) {
      this.loadingFile = true;
      try {
        const files = event.target.files;
        if (files?.length < 1) {
          event.target.value = null;
          return;
        }
        const documentFile = files[0];
        await UploadContract(documentFile, this.conractId);
        this.$emit('contractChanged')
      } catch (err) {
        const errorMessage = new HandleUploadError(err).message || err.message || this.$t('errorHappened');
        this.errorMessage(errorMessage)
      } finally {
        event.target.value = null;
        this.loadingFile = false
      }
    },
    downloadFile(){
      let xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      const _this = this;
      xhr.onload = function() {
        let blob = xhr.response;
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = _this.fileName;
        a.click();
      };
      xhr.onerror = function (){
        _this.errorMessage(_this.$t('errorHappened'))
      }
      xhr.open('GET', this.contractUrl);
      xhr.send();
    },
    async updateFile(){
      this.loadingFile = true;
      try {
        const files = event.target.files;
        if (files?.length < 1) {
          event.target.value = null;
          return;
        }
        const documentFile = files[0];
        await updateContractFile(this.contractUrl, this.conractId,documentFile);
        this.$emit('contractChanged')
      } catch (err) {
        const errorMessage = new HandleUploadError(err).message || err.message || this.$t('errorHappened');
        this.errorMessage(errorMessage)
      } finally {
        event.target.value = null;
        this.loadingFile = false
      }
    },
    async deleteFile(){
      const confirm = await Swal.fire({
        title: this.$t('areYouSure'),
        text: this.$t('deleteContract'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: this.$t('yesDelete'),
        cancelButtonText:this.$t('cancel')
      });
      if (!confirm.isConfirmed){
        return
      }
      this.loadingFile = true;
      try {
        await deleteContractFile(this.conractId);
        this.$emit('contractChanged')
      } catch (err) {
        const errorMessage = new HandleUploadError(err).message || err.message || this.$t('errorHappened');
        this.errorMessage(errorMessage)
      } finally {
        this.loadingFile = false
      }
    }
  }
}
</script>
<style scoped>
.uploadFile {
  height: 150px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  font-weight: 500;
  cursor: pointer;
  color: #999;
  border: 2px dashed rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.uploadFile i {
  font-size: 1.5rem;
}

.uploadFile input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: none;
}
</style>
<style scoped>
.text {
  font-weight: 900;
  font-size: 7rem;

  text-transform: uppercase;
}

.file-name {
  font-weight: 400;
  font-size: 14px;
}

.file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions {
  font-size: 1.2rem;
  display: flex;
  gap: 10px;
  color: #888!important;
  align-items: center;
}
.actions a{
  color: #888!important;
}
.actions i {
  cursor: pointer;
}

.date-modify {
  font-size: 13px;
  color: #888;
}
.update-file input{
  display: none;
}
.update-file{
  margin-bottom: 0;
}
.whatsapp-icon{
  color: #1cbb8c;
}
</style>