import "firebase/firestore";
import "firebase/auth";
import moment from "moment";
import axios from "axios";
import firebase from "firebase/app";
const db = firebase.firestore();
const storage = firebase.storage().ref();
const collectionRef = db.collection("Contract");
const users = db.collection("users");
import { pushNotification, create } from "./notification";
const agentID = JSON.parse(localStorage.getItem("auth.currentUser")).uid;

export function GetAllContracts() {
  return new Promise((resolve, reject) => {
    const data = [];

    collectionRef
      .get()
      .then((querySnapshot) => {
        GetAllContractTypes().then((res) => {
          let contractTypes = [];
          console.log(res);
          contractTypes = res[0].ContractTypes;

          querySnapshot.forEach(async (doc) => {
            const contractData = doc.data();
            const clientId = contractData.uid.id;
            let clientName = "";
            const found = contractTypes.findIndex(
              (el) =>
                el.en == contractData.CotractType ||
                el.ar == contractData.CotractType
            );
            if (found >= 0) {
              contractData.CotractType = contractTypes[found];
            }
            try {
              const clientDoc = await users.doc(clientId).get();

              if (clientDoc.exists) {
                clientName = clientDoc.data().display_name;
              } else {
                console.log("Client not found");
              }
            } catch (error) {
              console.error("Error fetching client data:", error);
            }
            data.push({
              id: doc.id,
              createdAt: nanosecondsToISO8601(doc.data().created_at.seconds),
              contractData,
              clientName,
              clientId,
            }); //contains the document data
            // console.log(data);
          });
          resolve(data);
        });
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
        reject(error);
      });
  });
}
export function GetContractsCount() {
  return new Promise((resolve, reject) => {
    collectionRef
      .get()
      .then((querySnapshot) => {
        resolve(querySnapshot.size);
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
        reject(error);
      });
  });
}
export function GetPendingContracts() {
  return new Promise((resolve, reject) => {
    const data = [];
    const query = collectionRef.where("ContractStatus", "==", 0);

    query
      .get()
      .then((querySnapshot) => {
        GetAllContractTypes().then((res) => {
          let contractTypes = [];
          console.log(res);
          contractTypes = res[0].ContractTypes;
          querySnapshot.forEach(async (doc) => {
            const contractData = doc.data();
            const clientId = contractData.uid.id;
            let clientName = "";
            const found = contractTypes.findIndex(
              (el) =>
                el.en == contractData.CotractType ||
                el.ar == contractData.CotractType
            );
            if (found >= 0) {
              contractData.CotractType = contractTypes[found];
            }
            try {
              const clientDoc = await users.doc(clientId).get();

              if (clientDoc.exists) {
                clientName = clientDoc.data().display_name;
              } else {
                console.log("Client not found");
              }
            } catch (error) {
              console.error("Error fetching client data:", error);
            }
            data.push({
              id: doc.id,
              createdAt: nanosecondsToISO8601(doc.data().created_at.seconds),
              contractData,
              clientName,
              clientId,
            }); //contains the document data
            // console.log(data);
          });
          resolve(data);
        });
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
        reject(error);
      });
  });
}
export async function GetContractById(id) {
  try {
    let doc = await collectionRef.doc(id).get();
    if (!doc.exists) {
      const findByContractID = await collectionRef
        .where("ContractID", "==", id)
        .limit(1)
        .get();
      findByContractID.forEach((el) => {
        doc = el;
      });
    }
    if (doc.exists) {
      const contractData = doc.data();
      const clientId = contractData.uid.id;
      let clientData = "";

      try {
        const clientDoc = await users.doc(clientId).get();

        if (clientDoc.exists) {
          clientData = clientDoc.data();
        } else {
          console.log("Client not found");
        }
      } catch (error) {
        console.error("Error fetching client data:", error);
      }
      try {
        GetAllContractTypes().then((res) => {
          let contractTypes = [];
          contractTypes = res[0].ContractTypes;
          const found = contractTypes.findIndex(
            (el) =>
              el.en == contractData.CotractType ||
              el.ar == contractData.CotractType
          );
          if (found >= 0) {
            contractData.CotractType = contractTypes[found];
          }
        });
      } catch (err) {
        console.log(err);
      }

      const data = {
        id: doc.id,
        createdAt: nanosecondsToISO8601(contractData.created_at.seconds),
        contractData,
        clientData,
        clientId,
      };
      // console.log(data);
      return data;
    } else {
      console.log("Contract not found");
      throw new Error("Contract not found");
    }
  } catch (error) {
    console.error("Error getting contract document:", error);
    throw error;
  }
}
export async function setPrice(
  docId,
  price,
  clientID,
  contractTitle,
  note,
  estimatedTiming
) {
  const docRef = collectionRef.doc(docId);
  const body = `تم تحديث سعر العقد الى ${price} دينار كويتي `;
  const title = contractTitle;
  try {
    await docRef.update({
      TotalPayment: Number(price),
      ContractStatus: 1,
      note,
      estimatedTiming,
    });
    await pushNotification(clientID, body, title);
    await create(clientID, agentID, title, body, "contract", docId);
    return true;
  } catch (error) {
    console.error("Error setting Price: ", error);
    return false;
  }
}
export async function markPaid(docId) {
  const docRef = collectionRef.doc(docId);

  try {
    await docRef.update({ ContractStatus: 2 });
    return true;
  } catch (error) {
    console.error("Error  marking Paid: ", error);
    return false;
  }
}
export async function markClosed(docId, clientID, contractTitle) {
  const docRef = collectionRef.doc(docId);
  const body = `تم غلق العقد الخاص بك `;
  const title = contractTitle;
  try {
    await docRef.update({ ContractStatus: 3 });
    pushNotification(clientID, body, title);
    create(clientID, agentID, title, body, "contract", docId);
    return true;
  } catch (error) {
    console.error("Error marking Closed: ", error);
    return false;
  }
}
export function GetAllContractTypes() {
  return new Promise((resolve, reject) => {
    const data = [];

    db.collection("ContractTypes")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(async (doc) => {
          data.push({
            id: doc.id,
            ...doc.data(),
          }); //contains the document data
          // console.log(data);
        });
        resolve(data);
      })
      .catch((error) => {
        console.error("Error getting documents:", error);
        reject(error);
      });
  });
}
export function AddContractTypes(id, nameEn, nameAr) {
  return new Promise((resolve, reject) => {
    const add = db.collection("ContractTypes").doc(id);

    add
      .get()
      .then((doc) => {
        if (doc.exists) {
          // Step 2: Retrieve the current array from the document
          const currentArray = doc.data().ContractTypes || []; // Retrieve the existing ContractTypes array

          // Step 3: Add the new contract type to the array
          currentArray.push({
            ar: nameAr,
            en: nameEn,
          });

          // Step 4: Update the document in Firestore with the modified array
          add
            .update({ ContractTypes: currentArray }) // Update the ContractTypes field with the modified array
            .then(() => {
              console.log("New contract type added in Firestore.");
              resolve("New contract type added in Firestore.");
            })
            .catch((error) => {
              console.error("Error updating document: ", error);
              reject("Error updating document.");
            });
        } else {
          // If the document does not exist, create it with the new contract type
          add
            .set({
              ContractTypes: [
                {
                  ar: nameAr,
                  en: nameEn,
                },
              ],
            })
            .then(() => {
              console.log(
                "Document created with new contract type in Firestore."
              );
              resolve("Document created with new contract type in Firestore.");
            })
            .catch((error) => {
              console.error("Error creating document: ", error);
              reject("Error creating document.");
            });
        }
      })
      .catch((error) => {
        console.error("Error getting document: ", error);
        reject("Error getting document.");
      });
  });
}

export function UpdateContractType(id, edit) {
  return new Promise((resolve, reject) => {
    const update = db.collection("ContractTypes").doc(id);

    update
      .get()
      .then((doc) => {
        if (doc.exists) {
          // Step 2: Retrieve the current array from the document
          const currentArray = doc.data().ContractTypes || []; // Retrieve the existing ContractTypes array

          // Step 3: Locate the object you want to edit within the array
          const contractToEdit = currentArray.find(
            (obj) => obj.en === edit.nameEn || obj.ar === edit.nameAr
          );

          if (contractToEdit) {
            // Step 4: Edit the object's properties
            if (edit.editnameEn) contractToEdit.en = edit.editnameEn;
            if (edit.editnameAr) contractToEdit.ar = edit.editnameAr;

            // Step 5: Update the document in Firestore with the modified array
            update
              .update({ ContractTypes: currentArray }) // Update the ContractTypes field with the modified array
              .then(() => {
                console.log("Contract type edited in the array in Firestore.");
                resolve("Contract type edited in the array in Firestore.");
              })
              .catch((error) => {
                console.error("Error updating document: ", error);
                reject("Error updating document.");
              });
          } else {
            console.log("Contract type not found in the array.");
            reject("Contract type not found in the array.");
          }
        } else {
          console.log("Document does not exist.");
          reject("Document does not exist.");
        }
      })
      .catch((error) => {
        console.error("Error getting document: ", error);
        reject("Error getting document.");
      });
  });
}

export function DeleteContractType(id, nameToDeleteEn, nameToDeleteAr) {
  return new Promise((resolve, reject) => {
    const Delete = db.collection("ContractTypes").doc(id);

    Delete.get()
      .then((doc) => {
        if (doc.exists) {
          // Step 2: Retrieve the current array from the document
          const currentArray = doc.data().ContractTypes || []; // Retrieve the existing ContractTypes array

          // Step 3: Locate the object you want to delete within the array
          const index = currentArray.findIndex(
            (obj) => obj.en === nameToDeleteEn || obj.ar === nameToDeleteAr
          );

          if (index !== -1) {
            // Step 4: Remove the object from the array
            currentArray.splice(index, 1);

            // Step 5: Update the document in Firestore with the modified array
            Delete.update({ ContractTypes: currentArray }) // Update the ContractTypes field with the modified array
              .then(() => {
                console.log(
                  "Contract type deleted from the array in Firestore."
                );
                resolve("Contract type deleted from the array in Firestore.");
              })
              .catch((error) => {
                console.error("Error updating document: ", error);
                reject("Error updating document.");
              });
          } else {
            console.log("Contract type not found in the array.");
            reject("Contract type not found in the array.");
          }
        } else {
          console.log("Document does not exist.");
          reject("Document does not exist.");
        }
      })
      .catch((error) => {
        console.error("Error getting document: ", error);
        reject("Error getting document.");
      });
  });
}

export async function UploadContract(file, docId) {
  const contractUrl = await uploadFile(generateUniqueFileName(file.name), file);
  const docRef = collectionRef.doc(docId);
  await docRef.update({
    contractUrl: contractUrl,
    contractDate: moment().format("LLL"),
  });
}
export async function updateContractFile(downloadURL, docId, file) {
  const decodedURL = decodeURIComponent(downloadURL);
  const fileName = decodedURL
    .substring(decodedURL.lastIndexOf("/") + 1)
    .split("?")[0];
  fileName;
  const contractUrl = await uploadFile(generateUniqueFileName(file.name), file);
  const docRef = collectionRef.doc(docId);
  await docRef.update({
    contractUrl: contractUrl,
    contractDate: moment().format("LLL"),
  });
}
export async function deleteContractFile(docId) {
  const docRef = collectionRef.doc(docId);
  await docRef.update({ contractDate: "", contractUrl: "" });
}
export async function sendContract(phoneNumber, message, docId) {
  const res = await axios.post(
    "https://us-central1-law-office-flutterflow.cloudfunctions.net/app/whatsapp/send",
    {
      phoneNumber,
      message,
    }
  );
  if (!res?.data?.status) {
    throw new Error(res?.data?.message);
  }
  const docRef = collectionRef.doc(docId);
  await docRef.update({ sendToWhatsapp: true });
}

async function uploadFile(fileName, file) {
  const currentUser = firebase.auth().currentUser;
  if (!currentUser) {
    throw new Error("User is not signed in.");
  }
  const userId = currentUser.uid;
  const fileRef = storage.child(`users/${userId}/${fileName}`);
  const res = await fileRef.put(file);
  return await res?.ref.getDownloadURL();
}

function generateUniqueFileName(originalFileName) {
  const timestamp = Date.now();
  const randomString = Math.random().toString(36).slice(2, 9);
  const fileExtension = originalFileName.split(".").pop();
  return `${timestamp}-${randomString}.${fileExtension}`;
}
function nanosecondsToISO8601(seconds) {
  const milliseconds = seconds * 1000; // Convert nanoseconds to milliseconds
  const date = new Date(milliseconds);
  return date;
}
