var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.title))]),_c('hr'),_vm._l((_vm.data),function(element){return _c('dl',{key:element.key,staticClass:"row mb-0"},[_c('dt',{staticClass:"col-sm-3",staticStyle:{"min-height":"30px","max-height":"200px","overflow-y":"auto"}},[_vm._v(" "+_vm._s(element.key)+" ")]),_c('dd',{staticClass:"col-sm-9",staticStyle:{"min-height":"30px","max-height":"200px","overflow-y":"auto"}},[_vm._v(" "+_vm._s(element.type ? element.type === "link" ? "" : "" : element.tags ? "" : element.internalLink ? "" : element.value)+" "),(
                element.type
                  ? element.type === 'link'
                    ? true
                    : false
                  : false
              )?_c('a',{attrs:{"href":element.value,"target":"_blank"}},[_vm._v("Open Zoom Meeting")]):_vm._e(),(element.type === 'time')?_c('bdi',[_vm._v(_vm._s(element.value))]):_vm._e(),(element.type === 'images')?_c('div',_vm._l((element.value),function(el){return _c('a',{key:el,attrs:{"download":"image.jpg","href":el}},[_c('img',{staticStyle:{"width":"100px","height":"100px","margin-inline":"10px"},attrs:{"src":el,"alt":"images"}})])}),0):_vm._e(),(element.type === 'documents')?_c('div',_vm._l((element.value),function(el,index){return _c('span',{key:el,staticStyle:{"margin-inline":"10px"}},[_c('a',{attrs:{"href":el}},[_vm._v("document "+_vm._s(index + 1))])])}),0):_vm._e(),(element.tags)?_c('h5',_vm._l((element.value),function(el){return _c('b-badge',{key:el,staticStyle:{"margin":"4px"},attrs:{"variant":"light"}},[_vm._v(_vm._s(el))])}),1):_vm._e(),(element.internalLink)?_c('router-link',{staticClass:"client-link",attrs:{"to":{name:'clientPage',params:{id:element.internalLink}}}},[_vm._v(_vm._s(element.value))]):_vm._e()],1)])})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }