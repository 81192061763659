<script>
export default {
  props: {
    data: { type: Array, default: () => [] }, // data
    title: { type: String, default: () => "" }, // Component title
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- card title -->
          <h4 class="card-title">{{ title }}</h4>
          <hr />
          <!-- data rows  -->
          <dl class="row mb-0" v-for="element in data" :key="element.key">
            <dt
              class="col-sm-3"
              style="min-height: 30px; max-height: 200px; overflow-y: auto"
            >
              {{ element.key }}
            </dt>

            <dd
              class="col-sm-9"
              style="min-height: 30px; max-height: 200px; overflow-y: auto"
            >
              <!-- if data type is link then it will view in anchor tag -->
              {{
                element.type
                  ? element.type === "link"
                    ? ""
                    : ""
                  : element.tags
                  ? ""
                  : element.internalLink ? "" : element.value
              }}
              <a
                :href="element.value"
                target="_blank"
                v-if="
                  element.type
                    ? element.type === 'link'
                      ? true
                      : false
                    : false
                "
                >Open Zoom Meeting</a
              >
              <bdi v-if="element.type === 'time'">{{element.value}}</bdi>
              <div v-if="element.type === 'images'">
                <a
                  download="image.jpg"
                  v-for="el in element.value"
                  :key="el"
                  :href="el"
                >
                  <img
                    style="width: 100px; height: 100px; margin-inline: 10px"
                    :src="el"
                    alt="images"
                  />
                </a>
              </div>
              <div v-if="element.type === 'documents'">
                <span
                  style="margin-inline: 10px"
                  v-for="(el, index) in element.value"
                  :key="el"
                  ><a :href="el">document {{ index + 1 }}</a></span
                >
              </div>
              <h5 v-if="element.tags">
                <b-badge
                  style="margin: 4px"
                  v-for="el in element.value"
                  :key="el"
                  variant="light"
                  >{{ el }}</b-badge
                >
              </h5>
              <router-link :to="{name:'clientPage',params:{id:element.internalLink}}" v-if="element.internalLink" class="client-link">{{element.value}}</router-link>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <!-- end col -->
  </div>
</template>
