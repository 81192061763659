export  function HandleUploadError(err) {
    switch (err.code) {
        case 'storage/unknown':
            this.message = 'An unknown error occurred.'
            break
        case 'storage/object-not-found':
            this.message = 'No object exists at the desired reference.'
            break
        case 'storage/bucket-not-found':
            this.message = 'No bucket is configured for Cloud Storage'
            break
        case 'storage/project-not-found':
            this.message = 'No project is configured for Cloud Storage'
            break
        case 'storage/quota-exceeded':
            this.message = "Quota on your Cloud Storage bucket has been exceeded. If you're on the no-cost tier, upgrade to a paid plan. If you're on a paid plan, reach out to Firebase support."
            break
        case 'storage/unauthenticated':
            this.message = "User is unauthenticated, please authenticate and try again."
            break
        case 'storage/unauthorized':
            this.message = "User is not authorized to perform the desired action, check your security rules to ensure they are correct."
            break
        case 'storage/retry-limit-exceeded':
            this.message = "The maximum time limit on an operation (upload, download, delete, etc.) has been excceded. Try uploading again."
            break
        case 'storage/invalid-checksum':
            this.message = "File on the client does not match the checksum of the file received by the server. Try uploading again."
            break
        case 'storage/canceled':
            this.message = "User canceled the operation."
            break
        case 'storage/invalid-event-name':
            this.message = "Invalid event name provided. Must be one of [`running`, `progress`, `pause`]"
            break
        case 'storage/invalid-url':
            this.message = `Invalid URL provided to refFromURL().`
            break
        case 'storage/invalid-argument':
            this.message = "The argument passed to put() must be `File`, `Blob`, or `UInt8` Array. The argument passed to putString() must be a raw, `Base64`, or `Base64URL` string."
            break
        case 'storage/no-default-bucket':
            this.message = "No bucket has been set in your config's storageBucket property."
            break
        case 'storage/cannot-slice-blob':
            this.message = "Commonly occurs when the local file has changed (deleted, saved again, etc.). Try uploading again after verifying that the file hasn't changed."
            break
        case 'storage/server-file-wrong-size':
            this.message = "File on the client does not match the size of the file received by the server. Try uploading again.\n"
            break
        default:
            this.message = null

    }
}