<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DataViewer from "@/components/elements/DataViewer.vue";
import swal from "sweetalert2";
import * as Contracts from "@/services/contracts";
import TheContract from "@/components/contracts/TheContract.vue";
import moment from "moment";
/**
 * Typography component
 */
export default {
  page: {
    title: "Contract",
    meta: [{ name: "Contract", content: appConfig.description }],
  },
  components: { DataViewer, Layout, PageHeader, TheContract },
  data() {
    return {
      title: this.$t("contract"),
      items: [
        {
          text: "Ylaw",
          href: "/",
        },
        {
          text: this.$t("menuitems.contracts.text"),
          href: "/contract/all",
        },
        {
          text: this.$t("contract_information"),

          active: true,
        },
      ],
      loadingSetPrice: false,
      data: [],
      paid: false,
      price: "",
      estimatedTiming: "",
      note: "",
      phoneReciver: "",
      whatsAppNote: "",
      loadingSendToWhatsapp: false,
      selectedTimePeriod: "days",
      timeOption: [
        {
          value: "hours",
          text: this.$t("hours"),
        },
        {
          value: "days",
          text: this.$t("days"),
        },
        {
          value: "months",
          text: this.$t("months"),
        },
      ],
    };
  },
  methods: {
    editPrice() {
      this.loadingSetPrice = true;
      Contracts.setPrice(
        this.data.id,
        this.price,
        this.data.clientData.uid,
        this.data.contractData.ContractTitle,
        this.note,
        this.estimatedTiming
      ).then(() => {
        this.successmsg("Contract Price has been Set Successfully");
        this.$bvModal.hide("modal-backdrop-set_a_price");
        this.loadContract();
        this.loadingSetPrice = false;
      });
    },
    markPaid() {
      Contracts.markPaid(this.data.id, this.data.clientData.uid).then(() => {
        this.successmsg("Contract Marked As Paid Successfully");
        this.$bvModal.hide("modal-backdrop-payment-recevied");
        this.loadContract();
      });
    },
    markClosed() {
      Contracts.markClosed(
        this.data.id,
        this.data.clientData.uid,
        this.data.contractData.ContractTitle
      ).then(() => {
        this.successmsg("Contract Marked As Closed Successfully");
        this.$bvModal.hide("modal-backdrop-mark_as_closed");
        this.loadContract();
      });
    },
    successmsg(msg) {
      swal.fire({
        position: "center",
        icon: "success",
        title: msg,
        showConfirmButton: false,
        timer: 1000,
      });
    },
    errormsg(msg) {
      swal.fire({
        position: "center",
        icon: "error",
        title: this.$t("ops"),
        showConfirmButton: true,
        text: msg,
      });
    },
    loadContract() {
      Contracts.GetContractById(this.$route.params.id).then((data) => {
        this.data = data;
        this.price = this.data?.contractData?.TotalPayment || "";
        let estimatedTiming = this.data?.contractData?.estimatedTiming || "";
        // estimatedTiming = estimatedTiming.split(" ");
        // if (estimatedTiming.length === 2) {
        //   this.estimatedTiming = estimatedTiming[0];
        //   this.selectedTimePeriod = estimatedTiming[1];
        // } else {
        //   this.estimatedTiming = "";
        //   this.selectedTimePeriod = "days";
        // }
        this.estimatedTiming = estimatedTiming;
        this.selectedTimePeriod = this.$t("days");

        this.note = this.data?.contractData?.note || "";
        this.phoneReciver = this.data?.clientData?.phone_number || "";
      });
    },
    contractChanged() {
      this.successmsg(this.$t("operationSuccess"));
      this.loadContract();
    },
    async sendToWhatsApp() {
      const oldLocal = moment.locale();
      moment.locale("ar");
      try {
        this.loadingSendToWhatsapp = true;
        const msg = `العنوان : ${
          this.data?.contractData?.ContractTitle
        }\nالنوع : ${
          this.data?.contractData?.CotractType
        }\n التاريخ : ${moment().format("LL")}${
          this.whatsAppNote?.length ? "\n" + this.whatsAppNote : ""
        }\n${this.data?.contractData?.contractUrl}`;
        await Contracts.sendContract(this.phoneReciver, msg, this.data.id);
        this.successmsg(this.$t("sentSuccessfully"));
        this.$bvModal.hide("modal-backdrop-send-to-whatsApp");
        this.whatsAppNote = "";
        this.phoneReciver = this.data?.clientData?.phone_number || "";
      } catch (err) {
        this.errormsg(err.message || this.$t("errorHappened"));
      } finally {
        this.loadingSendToWhatsapp = false;
        moment.locale(oldLocal);
      }
    },
  },
  created() {
    this.loadContract();
  },
  computed: {
    contractData() {
      const fields = [
        {
          key: this.$t("title"),
          value: this.data?.contractData?.ContractTitle,
        },
        {
          key: this.$t("type"),
          value:
            this.$i18n.locale == "en"
              ? this.data?.contractData?.CotractType?.en
              : this.data?.contractData?.CotractType?.ar,
        },

        {
          key: this.$t("status"),
          value:
            this.data?.contractData?.ContractStatus === 3
              ? this.$t("closed")
              : this.data?.contractData?.ContractStatus === 2
              ? this.$t("paid")
              : this.data?.contractData?.ContractStatus === 1
              ? this.$t("reviewed")
              : this.$t("not_reviewed"),
        },
      ];
      if (this.data?.contractData?.ContractDescription?.length) {
        fields.push({
          key: this.$t("description"),
          value: this.data?.contractData?.ContractDescription,
        });
      }
      if (this.data?.contractData?.TotalPayment) {
        fields.push({
          key: this.$t("price"),
          value: `${this.data?.contractData?.TotalPayment}  ${this.$t("KWD")}`,
        });
      }
      if (this.data?.contractData?.UploadedMedia?.length) {
        fields.push({
          key: this.$t("images"),
          value: this.data?.contractData?.UploadedMedia,
          type: "images",
        });
      }
      if (this.data?.contractData?.UploadedDocuments?.length) {
        fields.push({
          key: this.$t("client_documents"),
          value: this.data?.contractData?.UploadedDocuments,
          type: "documents",
        });
      }
      if (this.data?.contractData?.estimatedTiming) {
        fields.push({
          key: this.$t("estimatedTiming"),
          value: `${this.estimatedTiming} ( ${
            this.data?.contractData?.estimatedTiming > 1
              ? this.$t("days")
              : this.$t("day")
          } )`,
          type: "time",
        });
      }
      if (this.data?.contractData?.note?.length) {
        fields.push({
          key: this.$t("note"),
          value: this.data?.contractData?.note,
        });
      }
      return fields;
    },
    clientData() {
      return [
        {
          key: this.$t("name"),
          value: this.data?.clientData?.display_name,
          internalLink: this.data?.clientData?.uid,
        },
        { key: this.$t("phone"), value: this.data?.clientData?.phone_number },
        {
          key: this.$t("email"),
          value: this.data?.clientData?.email,
        },
      ];
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <DataViewer :data="contractData" :title="$t('contract_information')" />
    <TheContract
      :conract-id="$route.params.id"
      :contract-url="this.data?.contractData?.contractUrl"
      :contract-date="this.data?.contractData?.contractDate"
      v-if="this.data?.contractData?.ContractStatus >= 2"
      @contractChanged="contractChanged"
      @sendToWhatsApp="$bvModal.show('modal-backdrop-send-to-whatsApp')"
    />
    <DataViewer :title="$t('client_information')" :data="clientData" />
    <div class="row">
      <div class="col-4">
        <div class="card">
          <b-button
            @click="$bvModal.show('modal-backdrop-set_a_price')"
            :variant="'warning'"
            :disabled="data?.contractData?.ContractStatus >= 2"
          >
            {{ $t("set_a_price") }}
          </b-button>
          <b-modal
            no-close-on-backdrop
            id="modal-backdrop-set_a_price"
            :title="$t('set_a_price')"
            centered
            title-class="font-18"
          >
            <template #modal-footer>
              <div></div>
            </template>
            <form @submit.prevent="editPrice">
              <b-row class="set-price-input">
                <b-col cols="6" md="6">
                  <label for="price">price</label>

                  <div class="input-group">
                    <input
                      id="price"
                      type="number"
                      v-model="price"
                      class="form-control"
                      required
                      :placeholder="$t('price')"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">{{ $t("KWD") }}</span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="6" md="6">
                  <label for="estimatedTime">{{ $t("estimatedTiming") }}</label>
                  <div class="input-group">
                    <input
                      id="estimatedTime"
                      type="number"
                      v-model.trim.number="estimatedTiming"
                      min="0"
                      class="form-control"
                      required
                      :placeholder="$t('estimatedTiming')"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text">{{
                        estimatedTiming > 1 ? $t("days") : $t("day")
                      }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>

              <b-form-textarea
                id="textarea-large"
                class="form-control set-price-input text-area-note"
                size="lg"
                :placeholder="$t('note')"
                v-model.trim="note"
              ></b-form-textarea>
              <b-row style="margin-top: 10px">
                <b-col cols="8"></b-col>
                <b-col cols="2">
                  <b-button @click="$bvModal.hide('modal-backdrop-set_a_price')"
                    >{{ $t("cancel") }}
                  </b-button>
                </b-col>
                <b-col cols="2">
                  <b-overlay
                    :show="loadingSetPrice"
                    rounded
                    opacity="0.6"
                    spinner-small
                    spinner-variant="primary"
                    class="d-inline-block"
                  >
                    <b-button type="submit" variant="primary"
                      >{{ $t("ok") }}
                    </b-button>
                  </b-overlay>
                </b-col>
              </b-row>
            </form>
          </b-modal>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <b-button
            @click="$bvModal.show('modal-backdrop-payment-recevied')"
            :variant="'info'"
            :disabled="data?.contractData?.ContractStatus !== 1"
          >
            {{ $t("payment_recevied") }}
          </b-button>
          <b-modal
            no-close-on-backdrop
            id="modal-backdrop-payment-recevied"
            :title="$t('payment_recevied')"
            centered
            title-class="font-18"
          >
            <template #modal-footer>
              <div></div>
            </template>
            {{
              $t("Are_you_Sure_Want_to_Mark_This_Contract_As_Payment_Recevied")
            }}
            <b-row style="margin-top: 10px">
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button
                  @click="$bvModal.hide('modal-backdrop-payment-recevied')"
                  >{{ $t("cancel") }}
                </b-button>
              </b-col>
              <b-col cols="2">
                <b-button @click="markPaid()" variant="primary"
                  >{{ $t("ok") }}
                </b-button>
              </b-col>
            </b-row>
          </b-modal>
        </div>
      </div>
      <div class="col-4">
        <div class="card">
          <b-button
            @click="$bvModal.show('modal-backdrop-mark_as_closed')"
            :variant="'success'"
            :disabled="data?.contractData?.ContractStatus !== 2"
          >
            {{ $t("mark_as_closed") }}
          </b-button>
          <b-modal
            no-close-on-backdrop
            id="modal-backdrop-mark_as_closed"
            title="Edit Price"
            centered
            title-class="font-18"
          >
            <template #modal-footer>
              <div></div>
            </template>
            {{ $t("Are_you_Sure_Want_to_Mark_This_Contract_As_Closed") }}
            <b-row style="margin-top: 10px">
              <b-col cols="8"></b-col>
              <b-col cols="2">
                <b-button
                  @click="$bvModal.hide('modal-backdrop-mark_as_closed')"
                  >{{ $t("cancel") }}
                </b-button>
              </b-col>
              <b-col cols="2">
                <b-button @click="markClosed()" variant="primary"
                  >{{ $t("ok") }}
                </b-button>
              </b-col>
            </b-row>
          </b-modal>
        </div>
      </div>
      <b-modal
        no-close-on-backdrop
        id="modal-backdrop-send-to-whatsApp"
        :title="$t('sendContractToWhatsApp')"
        centered
        title-class="font-18"
      >
        <template #modal-footer>
          <div></div>
        </template>
        <form @submit.prevent="sendToWhatsApp">
          <label class="d-block">
            <span class="mb-2 d-block">{{ $t("customerWhatsApp") }}</span>
            <input
              type="tel"
              v-model.trim="phoneReciver"
              class="form-control"
              required
              :placeholder="$t('customerWhatsApp')"
            />
          </label>
          <b-form-textarea
            id="textarea-large"
            class="form-control set-price-input text-area-note"
            size="lg"
            :placeholder="$t('note')"
            v-model.trim="whatsAppNote"
          ></b-form-textarea>
          <b-row style="margin-top: 10px">
            <b-col cols="8"></b-col>
            <b-col cols="2">
              <b-button
                @click="$bvModal.hide('modal-backdrop-send-to-whatsApp')"
                >{{ $t("cancel") }}
              </b-button>
            </b-col>
            <b-col cols="2">
              <b-overlay
                :show="loadingSendToWhatsapp"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button type="submit" variant="primary">{{
                  $t("send")
                }}</b-button>
              </b-overlay>
            </b-col>
          </b-row>
        </form>
      </b-modal>
    </div>
  </Layout>
</template>
<style scoped>
.set-price-input {
  margin-top: 10px;
}

.text-area-note {
  padding: 0.47rem 0.75rem;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #505d69;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.text-area-note:focus,
select:focus {
  color: #505d69;
  background-color: #fff;
  border-color: #b1bbc4;
  outline: 0;
  box-shadow: none;
}
</style>
